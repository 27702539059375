import { Card } from '@mui/material'
import SimpleLoader, {
  ColorsEnum,
} from '../../../../components/simple-loader/simple-loader.component'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { useEffect } from 'react'
import ReactHtmlParser from 'html-react-parser'
import sanitizeHtml from 'sanitize-html'
import { IconAlts } from '../../models/caht.model'

const ChatItemComponent = ({ message, processing, setTrainerLoading }) => {
  useEffect(() => {
    setTimeout(() => {
      setTrainerLoading(false)
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ChatMessageWrapper role={message.role}>
      <Card className="mb-3">
        {message.role === 'user' ? (
          <div className="user-message whitespace-pre-wrap p-5" color="dark">
            {ReactHtmlParser(
              sanitizeHtml(message.content, {
                allowedTags: ['strong'],
                allowedAttributes: false,
              }),
            )}
          </div>
        ) : message.role === 'system' ? (
          <div className="system-message whitespace-pre-wrap p-5" color="dark">
            {processing ? (
              <SimpleLoader color={ColorsEnum.WHITE} />
            ) : (
              ReactHtmlParser(
                sanitizeHtml(message.content, {
                  allowedTags: ['strong', 'b'],
                  allowedAttributes: false,
                }),
              )
            )}
          </div>
        ) : (
          <div className="p-5">
            <div>
              <ReactMarkdown
                children={sanitizeHtml(message.content, {
                  allowedTags: ['strong'],
                  allowedAttributes: false,
                })}
                className="reactMarkDown"
                rehypePlugins={[rehypeRaw]}
              />
              {processing && <SimpleLoader />}
            </div>
          </div>
        )}
      </Card>
    </ChatMessageWrapper>
  )
}

export default ChatItemComponent

const ChatMessageWrapper = ({ children, role }) => {
  if (role === 'user')
    return (
      <div className="flex justify-between gap-2">
        <div className="ml-2 flex w-[64px] items-center"></div>
        <div className="max-w-[calc(100%-(166px))] grow px-4">{children}</div>
        <div className="flex h-[64px] w-[64px] items-center">
          <div className="relative h-[64px]">
            <img src={`./avatars/avatar-user.svg`} alt={IconAlts[role]} title={IconAlts[role]} />
            <img
              className="absolute -left-[46px] bottom-1/2"
              src={`./avatars/polygons/polygon-user.svg`}
              alt=""
            />
          </div>
        </div>
      </div>
    )

  return (
    <div className="flex justify-between gap-2">
      <img src={`./avatars/avatar-user`} alt="" />
      <div className="flex h-[64px] w-[64px] items-center">
        <div className="relative h-[64px]">
          <img src={`./avatars/avatar-${role}.svg`} alt={IconAlts[role]} title={IconAlts[role]} />
          <img
            className="absolute -right-[40px] bottom-1/2"
            src={`./avatars/polygons/polygon-${role}.svg`}
            alt=""
          />
        </div>
      </div>
      <div className="max-w-[calc(100%-(166px))] grow px-4">{children}</div>
      <div className="flex w-[64px] items-center"></div>
    </div>
  )
}
