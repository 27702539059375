import { Config } from "../../../auth/config";
import { baseUrl, lerwerkGPTAxios } from "../../../services/axios.config";
import { ConversationRequest } from "../models/caht.model";

export const getLernwerkGPTResult = async (mesageToGetKeywordsFrom: string) => {
  const response = await lerwerkGPTAxios.post("", {
    message: mesageToGetKeywordsFrom,
  });
  return response;
};

export async function conversationApi(
  conversation: ConversationRequest,
  abortSignal: AbortSignal
): Promise<Response> {
  const headers = new Headers({
    Authorization: `Bearer ${Config.bearerToken}`,
    "Content-Type": "application/json",
  });

  const gptRequest = {
    messages: [],
    max_tokens: 2500,
    temperature: 0.1,
    top_p: 0.95,
    frequency_penalty: 0,
    presence_penalty: 0,
    stream: true,
  };
  gptRequest.messages = conversation.messages;

  const response = await fetch(baseUrl, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(gptRequest),
    signal: abortSignal,
  });
  return response;
}
