export class Config {
  static client_id = "78bb6df3-e163-4651-8a88-d8a159af9a8d";
  static authority =
    "https://login.microsoftonline.com/338159a0-dd8f-45af-9672-faaa54bc6b24";
  static appScopes = ["78bb6df3-e163-4651-8a88-d8a159af9a8d/.default"];
  static bearerToken = process.env.REACT_APP_TOKEN;
}

export const lernwerkFeURL =
  "https://lernwerk-int.exxeta.network/learningcontent/";
