import { gptFunctionsApi } from '../../../services/axios.config'

let loadedTraining = null

export const getLernwerkArticles = async () => {
  const GET_ARTICLES = 'lernwerk-get-gpt-articles'
  const { data } = (await gptFunctionsApi.get(GET_ARTICLES)) as any

  return data.learningContents
}

export const getLernwerkArticlesSearch = async (languageName: string) => {
  const GET_ARTICLES_SEARCH = 'lernwerk-get-gpt-articles-search'
  const { data } = (await gptFunctionsApi.get(GET_ARTICLES_SEARCH, {
    params: { language: languageName },
  })) as any

  // when using the search endpoint, the results are not in the same format as the regular articles endpoint
  // so we need to map the results to match the regular articles endpoint
  return data.results.map((result) => result.value)
}

export const getLernwerkArticleContent = async (id) => {
  const GET_CONTENT = 'lernwerk-get-gpt-article-content?contentId='
  const { data } = (await gptFunctionsApi.get(`${GET_CONTENT}${id}`)) as any
  loadedTraining = data
  return data
}

export const getLernwerkArticleContentCached = async (id) => {
  if (loadedTraining?.id === id) return loadedTraining
  return await getLernwerkArticleContent(id)
}
