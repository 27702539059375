import axios from "axios";

const lerwerkGPTAxios = axios.create({
  baseURL: `https://gpt-keywords-api.azurewebsites.net/api/gpt-keywords-api`,
});

const gptFunctionsApi = axios.create({
  baseURL: `https://gpt-keywords-api.azurewebsites.net/api`,
});

const baseUrl = `https://ai.exxeta.com/api/v2/azure/openai/deployments/gpt-35-turbo/chat/completions`;



export { lerwerkGPTAxios, gptFunctionsApi, baseUrl };
